import { ChangeDetectorRef, Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { interval, Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-countdown-timer',
  standalone: true,
  imports: [CommonModule,TranslateModule],
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent {
  private sub: Subscription;
  timeLeft = { days: '00', hours: '00', minutes: '00', seconds: '00' };

  constructor(
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  ngOnInit(): void {
    this.updateCountdown();
    this.updateTopValue(); 

    this.sub = interval(1000).subscribe(() => {
      this.updateCountdown();
    });

    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('resize', this.updateTopValue.bind(this));
    }
  }

  updateCountdown() {
    const targetDate = new Date('2025-04-22T23:59:59').getTime();
    const now = new Date().getTime();
    const timeDiff = targetDate - now;

    if (timeDiff > 0) {
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000).toString().padStart(2, '0');

      this.timeLeft = { days, hours, minutes, seconds };
    } else {
      this.timeLeft = { days: '00', hours: '00', minutes: '00', seconds: '00' };
    }
    this.cdr.detectChanges();
  }

  updateTopValue() {
    const viewportHeight = window.innerHeight; 
    const adjustedValue = viewportHeight - 774; 
    console.log(adjustedValue)
    document.documentElement.style.setProperty('--dynamic-top', `${adjustedValue}px`);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('resize', this.updateTopValue.bind(this));
    } 
  }

}
