import { HydraBase, HydraCollection, HydraMember } from "@adeo/ngx-kozikaza-api";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { environment } from "environments/environment";
import { CookieService } from "ngx-cookie";
import { BehaviorSubject, Observable } from "rxjs";
import * as html2pdf from 'html2pdf.js';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export interface Shortcut extends HydraBase ,HydraMember {
  label: string;
  position: number;
}


const CONFIG =   {
  discussionBatchSize: 20,
  commentBatchSize: 50,
  retryTimeout: 30000,
  mutationTimeout: 8000,
  scrollDelay: 1000, // Increased for production to ensure loading
  maxScrollAttempts: 50
};
// Utility class for expanding content
class Expander {
  constructor(
    selector: string,
    containerSelector: string,
    label: string,
    batchSize: number
  ) {
    this.selector = selector;
    this.containerSelector = containerSelector;
    this.label = label;
    this.batchSize = batchSize;
  }

  private selector: string;
  private containerSelector: string;
  private label: string;
  private batchSize: number;
 
  async process() {
    let pass = 1;
    while (true) {
      const elements = Array.from(document.querySelectorAll(this.selector));
      if (elements.length === 0) {
        console.log(`✅ No more ${this.label}s to process.`);
        break;
      }
      console.log(`🔄 Pass #${pass}: Found ${elements.length} expandable ${this.label}(s).`);
      await this.processBatches(elements);
      pass++;
    }
    console.log(`✅ All passes completed for ${this.label}.`);
  }

  async processBatches(elements: Element[]) {
    const totalElements = elements.length;
    const totalBatches = Math.ceil(totalElements / this.batchSize);
    for (let i = 0; i < totalElements; i += this.batchSize) {
      const batch = elements.slice(i, i + this.batchSize);
      console.log(`🚀 Processing batch ${Math.floor(i / this.batchSize) + 1}/${totalBatches} (${batch.length} elements)...`);
      await Promise.all(batch.map((button, index) => 
        this.clickAndWait(button as HTMLElement, i + index + 1, totalElements)
      ));
    }
  }

  async clickAndWait(button: HTMLElement, index: number, total: number) {
    const buttonText = button.textContent?.trim() || '';
    console.log(`📤 (${index}/${total}) Processing '${buttonText}'...`);
    button.click();
    await this.waitForNewContent(this.containerSelector);
  }

  private wait(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private waitForNewContent(targetSelector: string): Promise<void> {
    return new Promise((resolve) => {
      const observer = new MutationObserver((mutationsList, observer) => {
        if (mutationsList.some(m => m.type === "childList" && m.addedNodes.length > 0)) {
          observer.disconnect();
          console.log("✅ New element detected, skipping...");
          resolve();
        }
      });
      const targetNode = document.querySelector(targetSelector);
      if (!targetNode) {
        console.warn(`⚠️ Container '${targetSelector}' not found, skipping.`);
        resolve();
        return;
      }
      observer.observe(targetNode, { childList: true, subtree: true });
      setTimeout(() => {
        observer.disconnect();
        console.warn("⏳ Timeout expired without detecting new items, skipping.");
        resolve();
      }, CONFIG.mutationTimeout);
    });
  }
}
@Injectable({
    providedIn: 'root'
  })
  export class SharedService {
    private renderer: Renderer2;

 
    public downloadedScreenShot$ = new BehaviorSubject<boolean>(false); 
    screenShotIsDownloading = new BehaviorSubject<boolean>(false);
    loadingMessage = new BehaviorSubject<string>('');
    /* to be switched to the sdk*/
    private _locale: Locale;
    apiUrl = environment.API_BACKEND_ORIGIN
 
    constructor(private http:HttpClient,private cookiesService:CookieService,rendererFactory: RendererFactory2){
      this.renderer = rendererFactory.createRenderer(null, null);

    }
    getShortcuts() {
       return  this.http.get(this.apiUrl+'/discover_shortcuts',{ headers: this.getHeaders() });
    }
    downloadPics(page: number) {
      return this.http.get(`${this.apiUrl}/download-pictures?page=${page}`, {
        headers: this.getHeaders(),
        responseType: 'blob' as 'json'
      });
    }

   set downloadedScreenShot(value:boolean){
    this.downloadedScreenShot$.next(value)
   }
   get downloadedScreenShot():boolean{
    return this.downloadedScreenShot$.getValue()
   }
  
    public getHeaders(): HttpHeaders {
        const bearerToken = this.cookiesService.get(environment.ACCES_TOKEN);
         return new HttpHeaders({
          Authorization: `Bearer ${bearerToken}`,
        });
      }

      /*executeScript() {
        const script = this.renderer.createElement('script');
        script.text = this.getScriptContent();
        this.renderer.appendChild(document.body, script);
      }
    
      private getScriptContent(): string {
        return `
          (async function() {
            const DEBUG = localStorage.getItem("DEBUG") === "true";
            const CONFIG = DEBUG ? { discussionBatchSize: 50, commentBatchSize: 200, retryTimeout: 10000, mutationTimeout: 3500, scrollDelay: 300, maxScrollAttempts: 20 }
                                : { discussionBatchSize: 20, commentBatchSize: 50, retryTimeout: 30000, mutationTimeout: 8000, scrollDelay: 500, maxScrollAttempts: 50 };
    
            class Expander {
                constructor(selector, containerSelector, label, batchSize) {
                    this.selector = selector;
                    this.containerSelector = containerSelector;
                    this.label = label;
                    this.batchSize = batchSize;
                }
    
                async process() {
                    let pass = 1;
                    while (true) {
                        const elements = Array.from(document.querySelectorAll(this.selector));
                        if (elements.length === 0) break;
                        await this.processBatches(elements);
                        pass++;
                    }
                }
    
                async processBatches(elements) {
                    for (let i = 0; i < elements.length; i += this.batchSize) {
                        const batch = elements.slice(i, i + this.batchSize);
                        await Promise.all(batch.map(button => this.clickAndWait(button)));
                    }
                }
    
                async clickAndWait(button) {
                    button.click();
                    await new Promise(resolve => setTimeout(resolve, CONFIG.mutationTimeout));
                }
            }
    
            async function fetchStory() {
                let lastHeight = 0, newHeight = document.body.scrollHeight, attempts = 0;
                while (attempts < CONFIG.maxScrollAttempts) {
                    window.scrollTo(0, newHeight);
                    await new Promise(resolve => setTimeout(resolve, CONFIG.scrollDelay));
                    if (newHeight === lastHeight) attempts++;
                    else attempts = 0;
                    lastHeight = newHeight;
                    newHeight = document.body.scrollHeight;
                }
            }
    
            async function loadAllImages() {
                let scrollPosition = 0, pageHeight = document.body.scrollHeight;
                while (scrollPosition < pageHeight) {
                    scrollPosition += 300;
                    window.scrollTo(0, scrollPosition);
                    await new Promise(resolve => setTimeout(resolve, 50));
                }
            }
    
            async function runScript() {
                await fetchStory();
                await new Expander("button.kz-post-discussion--comments-loadmore", ".kz-post-description", "discussion", CONFIG.discussionBatchSize).process();
                await new Expander(".read-more-cta .read-more-span", ".kz-post-description", "comment", CONFIG.commentBatchSize).process();
                await loadAllImages();
            }
    
            await runScript();
          })();
        `;
      }*/
        async captureAndDownloadPDF() {
          this.screenShotIsDownloading.next(true);
          try {
            this.loadingMessage.next('Déroulement de la page en cours...');
            await this.expandContent();
      
            this.loadingMessage.next('Stabilisation du contenu en cours...');
            await this.waitForContentToStabilize();
      
            this.loadingMessage.next('Préparation de la capture d’écran...');
            const fullHeight = document.body.scrollHeight;
            const fullWidth = document.body.scrollWidth;
            console.log(`📸 Capturing screen: ${fullWidth}px wide, ${fullHeight}px tall`);
      
            // Hide loader before capturing
            this.screenShotIsDownloading.next(false);
            await new Promise(resolve => setTimeout(resolve, 100)); // Ensure DOM updates
      
            const canvas = await html2canvas(document.body, {
              scale: 1,
              useCORS: true,
              scrollX: 0,
              scrollY: 0,
              width: fullWidth,
              height: fullHeight,
              windowWidth: fullWidth,
              windowHeight: fullHeight
            });
      
            const base64Image = canvas.toDataURL('image/png');
            console.log(`✅ Canvas generated: ${canvas.width}px x ${canvas.height}px`);
      
            // Show loader again for PDF generation
            this.screenShotIsDownloading.next(true);
            this.loadingMessage.next('Génération du fichier PDF en cours...');
            this.downloadAsPDF(base64Image);
      
            this.loadingMessage.next('Téléchargement terminé !');
            await new Promise(resolve => setTimeout(resolve, 2000));
            window.location.reload(); // Display success for 2 seconds
          } catch (error) {
            console.error('❌ Error capturing screen:', error);
            this.loadingMessage.next('Erreur lors de la capture ou génération du PDF.');
            await new Promise(resolve => setTimeout(resolve, 2000));
          } finally {
            this.screenShotIsDownloading.next(false);
            this.loadingMessage.next('');
          }
        }
      
        private async expandContent() {
          this.loadingMessage.next('Chargement de l’histoire complète...');
          await this.fetchStory();
          this.loadingMessage.next('Chargement des discussions...');
          await new Expander("button.kz-post-discussion--comments-loadmore", ".kz-post-description", "discussion", CONFIG.discussionBatchSize).process();
          this.loadingMessage.next('Chargement des commentaires...');
          await new Expander(".read-more-cta .read-more-span", ".kz-post-description", "comment", CONFIG.commentBatchSize).process();
          this.loadingMessage.next('Chargement des images et publications...');
          await this.loadAllImages();
          this.loadingMessage.next('Masquage des éléments inutiles...');
          this.hideElements([
            ".kz-header",
            ".kz-navbar.ng-star-inserted",
            ".kz-navbar",
            ".kz-kaza--options",
            ".kz-kaza-header--user-more.ng-star-inserted",
            "app-countdown-timer .maintenance",
            "app-footer"
          ]);
        }
      
        private async fetchStory() {
          let lastHeight = 0;
          let newHeight = document.body.scrollHeight;
          let attempts = 0;
          console.log("🔽 Scrolling to load the whole story...");
          while (attempts < CONFIG.maxScrollAttempts) {
            window.scrollTo(0, newHeight);
            await new Promise(resolve => setTimeout(resolve, CONFIG.scrollDelay));
            lastHeight = newHeight;
            newHeight = document.body.scrollHeight;
            if (newHeight === lastHeight) attempts++;
            else attempts = 0;
          }
          console.log("✅ Story fully loaded.");
        }
      
        private async loadAllImages(scrollStep = 300, scrollDelay = CONFIG.scrollDelay) {
          console.log("🔼 Scrolling to top...");
          window.scrollTo(0, 0);
          await new Promise(resolve => setTimeout(resolve, 500));
      
          console.log("🔽 Scrolling down to trigger image and post loading...");
          let scrollPosition = 0;
          let pageHeight = document.body.scrollHeight;
          let lastHeight = 0;
          let attempts = 0;
      
          while (attempts < CONFIG.maxScrollAttempts) {
            scrollPosition += scrollStep;
            window.scrollTo(0, scrollPosition);
            await new Promise(resolve => setTimeout(resolve, scrollDelay));
            lastHeight = pageHeight;
            pageHeight = document.body.scrollHeight;
            if (scrollPosition >= pageHeight) {
              if (pageHeight === lastHeight) attempts++;
              else attempts = 0;
            }
            console.log(`📏 Scroll position: ${scrollPosition}px, Page height: ${pageHeight}px, Attempts: ${attempts}`);
          }
          console.log("✅ All images and posts loaded.");
        }
      
        private async waitForContentToStabilize(maxWait = 5000, interval = 500) {
          console.log("⏳ Waiting for content to stabilize...");
          let lastHeight = document.body.scrollHeight;
          let stableCount = 0;
          const startTime = Date.now();
      
          while (Date.now() - startTime < maxWait) {
            await new Promise(resolve => setTimeout(resolve, interval));
            const currentHeight = document.body.scrollHeight;
            if (currentHeight === lastHeight) {
              stableCount++;
              if (stableCount >= 3) {
                console.log("✅ Content stabilized.");
                return;
              }
            } else {
              stableCount = 0;
              lastHeight = currentHeight;
              console.log(`📏 Height changed to ${currentHeight}px, waiting...`);
            }
          }
          console.warn("⚠️ Content stabilization timeout reached.");
        }
      
        private hideElements(selectors: string[]) {
          selectors.forEach(selector => {
            const element = document.querySelector(selector);
            if (element) element.setAttribute('style', 'display: none');
          });
        }
      
        private downloadAsPDF(base64Image: string) {
          this.loadingMessage.next('En cours de téléchargement...');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();
          const margin = 10;
          const maxWidth = pageWidth - 2 * margin;
          const maxHeight = pageHeight - 2 * margin;
      
          const imgProps = pdf.getImageProperties(base64Image);
          const imgWidth = imgProps.width;
          const imgHeight = imgProps.height;
          console.log(`📄 Image dimensions: ${imgWidth}px x ${imgHeight}px`);
      
          const scale = maxWidth / imgWidth;
          const scaledWidth = maxWidth;
          const scaledHeight = imgHeight * scale;
      
          if (scaledHeight <= maxHeight) {
            const x = margin;
            const y = margin;
            pdf.addImage(base64Image, 'PNG', x, y, scaledWidth, scaledHeight, undefined, 'NONE');
            console.log("📜 Single-page PDF created.");
            pdf.save('screenshot.pdf');
          } else {
            const img = new Image();
            img.src = base64Image;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d')!;
              canvas.width = imgWidth;
      
              const pixelsPerPage = maxHeight / scale;
              let position = 0;
              let pageCount = 0;
      
              while (position < imgHeight) {
                if (pageCount > 0) pdf.addPage();
                const remainingHeight = imgHeight - position;
                const pageHeightPixels = Math.min(pixelsPerPage, remainingHeight);
                canvas.height = pageHeightPixels;
      
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, position, imgWidth, pageHeightPixels, 0, 0, imgWidth, pageHeightPixels);
      
                const segmentBase64 = canvas.toDataURL('image/png');
                const segmentHeightMm = pageHeightPixels * scale;
                const x = margin;
                const y = margin;
      
                pdf.addImage(segmentBase64, 'PNG', x, y, scaledWidth, segmentHeightMm, undefined, 'NONE');
                console.log(`📜 Added page ${pageCount + 1}: ${segmentHeightMm}mm height`);
                position += pageHeightPixels;
                pageCount++;
              }
              console.log(`📜 Multi-page PDF created with ${pageCount} pages.`);
              pdf.save('screenshot.pdf');
            };
          }
        }
      }